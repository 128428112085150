import { sendToDataLayer } from '@utils';
export enum BookingRetrieveStatus {
  Success = 'success',
  Fail = 'fail'
}
export const trackBookingRetrieve = (status: BookingRetrieveStatus) => {
  sendToDataLayer(undefined, {
    event_category: 'homepage_section',
    event_action: `check_${status}`,
    event_label: 'booking_status_check'
  });
};
