// FEATURES
let _DUMMY_APPLICATION = false;

if (process.env.GATSBY_STAGE === 'production') {
  _DUMMY_APPLICATION = process.env.GATSBY_DUMMY_APPLICATION_TOGGLE == 'enable';
} else {
  _DUMMY_APPLICATION = process.env.GATSBY_DUMMY_APPLICATION_TOGGLE == 'enable';
}

export const DUMMY_APPLICATION = _DUMMY_APPLICATION;
