import { PureComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import Context, { ContextType } from '@ui/components/context';
import { DUMMY_APPLICATION } from '@features';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import SectionSearch from '@shares/search';
import SectionBookingRetrieve from '@pages/homepage/sections/booking-retrieve';
import SectionCarDeals from '@pages/homepage/sections/car-deals';
import SectionPromotions from '@shares/promotions';
import SectionValueProps from '@shares/value-props';
import SectionServices from '@shares/services';
import SectionRegions from '@shares/regions';
import SectionApplication from '@pages/homepage/sections/application';
import { IntroduceModal } from '@pages/homepage/components/pboy-modal';
import { PromotionModal } from '@pages/homepage/components/promotion-modal';
import { AppBanner } from '@shares/app-banner';

import type { PromotionModalResult } from '@pages/homepage/components/promotion-modal';

import moment from 'moment';
import { PageSection } from '@utils';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import './style.scss';

interface QueryResult {
  homepage: {
    edges: [
      {
        node: {
          frontmatter: {
            defaultCity: string;
            description: string;
            header: string;
            hero: IGatsbyImageData;
            keywords: string;
            subTitle: string;
            title: string;
          };
        };
      }
    ];
  };
  promotionModal: {
    edges: [
      {
        node: {
          frontmatter: PromotionModalResult;
        };
      }
    ];
  };
  siteDescription: {
    edges: [
      {
        node: {
          id: number;
          frontmatter: {
            short_value_props: {
              content_th: string;
              content_en: string;
            }[];
          };
        };
      }
    ];
  };
}

interface Props extends PageWithTranslationProps {
  data: QueryResult;
}

const IndexPage = (props: PageWithTranslationProps) => (
  <StaticQuery
    query={graphql`
      query {
        homepage: allMarkdownRemark(filter: { fields: { collection: { eq: "homepage" } } }) {
          edges {
            node {
              frontmatter {
                hero {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                header
                title
                subTitle
                description
                keywords
                defaultCity
              }
            }
          }
        }
        promotionModal: allMarkdownRemark(filter: { fields: { collection: { eq: "promotion-modal" } } }) {
          edges {
            node {
              frontmatter {
                kind
                path
                activate
                redirect_to_blog
                title_th
                title_en
                img_th {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                img_en {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                start
                end
              }
            }
          }
        }
        siteDescription: allMarkdownRemark(filter: { fields: { collection: { eq: "site_description" } } }) {
          edges {
            node {
              id
              frontmatter {
                short_value_props {
                  content_th
                  content_en
                }
              }
            }
          }
        }
      }
    `}
    render={(data: QueryResult) => {
      console.log(data);

      return (
        <Context>
          <Index {...props} data={data} />
        </Context>
      );
    }}
  />
);

class Index extends PureComponent<Props> {
  static defaultProps = {
    prefix: typeof window === 'object' ? window.localStorage.getItem('i18nextLng') || 'th' : 'th'
  };
  static contextType = ContextType;

  get popupModal() {
    const { data, prefix } = this.props;
    const { edges } = data.promotionModal;
    const modalPromotion = edges[0].node.frontmatter;

    // const privilegeStartDate = moment('2022-02-01', 'YYYY-MM-DD');
    // const privilegeEndDate = moment('2022-12-31', 'YYYY-MM-DD');

    const privilegeStartDate = moment.tz(modalPromotion.start, 'Asia/Bangkok');
    const privilegeEndDate = moment.tz(modalPromotion.end, 'Asia/Bangkok');
    if (
      moment.tz(moment(), 'Asia/Bangkok').isSameOrAfter(privilegeStartDate) &&
      moment.tz(moment(), 'Asia/Bangkok').isSameOrBefore(privilegeEndDate) &&
      modalPromotion.activate
    ) {
      return <PromotionModal modal={modalPromotion} prefix={prefix} />;
    }

    return <IntroduceModal />;
  }

  render() {
    const { t, prefix, data } = this.props;
    const { homepage, siteDescription } = data;
    const { title, description, keywords, hero, subTitle, header } = homepage.edges[0].node.frontmatter;

    const valueProps = siteDescription.edges[0].node.frontmatter.short_value_props;
    const { isMobile } = this.context;

    return (
      <Layout prefix={prefix} section={PageSection.Home} isTransparent>
        {this.popupModal}
        <SEO prefix={prefix} title={header} description={description} keywords={keywords} />
        <div>
          <SectionSearch
            backgroundSrc={hero}
            valueProps={valueProps}
            t={t}
            prefix={prefix}
            sectionName={PageSection.Home}
          >
            <div className="heading-text">
              <h1>{title}</h1>
              <h4>{subTitle}</h4>
            </div>
          </SectionSearch>
          <SectionPromotions prefix={prefix} isMobile={isMobile} />
          <AppBanner />
          <SectionCarDeals prefix={prefix} />
          <SectionValueProps sectionName={PageSection.Home} />
          <SectionServices prefix={prefix} sectionName={PageSection.Home} />
          <SectionRegions prefix={prefix} sectionName={PageSection.Home} />
          {DUMMY_APPLICATION && <SectionApplication />}
          <SectionBookingRetrieve />
        </div>
      </Layout>
    );
  }
}
export default withTrans()(IndexPage);
