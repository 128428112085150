import { FunctionComponent, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ApplicationFormField from './app-form-fields';
import DHPicture from '@shares/picture';
import { ApplicationType } from '../../sections/application';
import { ApplicationFormValues, applicationSchema } from './app-form-fields';
import { withFormik, InjectedFormikProps } from 'formik';
import { MARKETING_API } from '@config';
import './style.scss';

interface ApplicationModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  type: ApplicationType;
}

const ApplicationForm: FunctionComponent<InjectedFormikProps<ApplicationModalProps, ApplicationFormValues>> = (
  props
) => {
  const { visible, setVisible, status, type } = props;
  const isFormAlert = status ? (status === 200 ? false : true) : false;

  useEffect(() => {
    if (props.values.email === '') props.setErrors({});
    if (props.status === 200) props.setVisible(false);
  });

  return (
    <Modal className="modal--application" centered show={visible} onHide={() => setVisible(!visible)}>
      <i className="icon-close" onClick={() => setVisible(!visible)} />
      <Modal.Body className="text-center">
        <DHPicture
          imgClassName="app-img"
          webpScr="/assets/shares/dh-app-icon.webp"
          normalSrc="/assets/shares/dh-app-icon.png"
          alt={type}
        />
        <h3>แอปพลิเคชั่นของเราอยู่ระหว่างพัฒนา</h3>
        <p>
          กรุณากรอกอีเมลของคุณด้านล่าง <br /> ระบบจะแจ้งเตือนเมื่อแอปพลิเคชั่นพร้อมให้บริการ
        </p>
        <Form onSubmit={(e: any) => props.handleSubmit(e)} className="d-flex justify-content-center">
          <ApplicationFormField
            isError={isFormAlert}
            errors={props.errors}
            values={props.values}
            handleChange={props.handleChange}
            handleBlur={() => {
              if (props.values.email === '') props.setErrors({});
              return;
              // props.handleBlur(e);
            }}
            isSubmitting={props.isSubmitting}
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const ApplicationModal = withFormik<ApplicationModalProps, ApplicationFormValues>({
  mapPropsToValues: (props) => ({
    email: '',
    platform: props.type
  }),
  validationSchema: applicationSchema(),
  handleSubmit: (values, formikBag) => {
    axios({
      url: `${MARKETING_API}/application_waiting_list`,
      method: 'post',
      data: {
        email: values.email.toLowerCase(),
        platform: values.platform
      }
    })
      .then(() => {
        formikBag.setSubmitting(false);
        formikBag.setStatus(200);
        // sendToGAByFreeText('mobile_app_dummy', 'subscribe', '');
      })
      .catch((e) => {
        formikBag.setSubmitting(false);
        formikBag.setStatus(200);
        console.log('Error', e);
      });
  },
  enableReinitialize: true
})(ApplicationForm);

export default ApplicationModal;
