import { createRef, FunctionComponent, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { WEB_SEARCH_LINK } from '@config';
import { appendParamsCurrentPage } from '@utils';
import { trackCarDealCard, trackCarDealCardImpression } from './tracking';
import { CardProps } from './interface';

export const CarDealCard: FunctionComponent<CardProps> = (cardProps: CardProps) => {
  const CarDealCardRef = createRef<HTMLAnchorElement>();
  const { deal, t } = cardProps;

  useEffect(() => {
    const carDealBoxRef = document.getElementById('car-deals-box');
    if (!carDealBoxRef) return;

    const CarDealCardObserver = new IntersectionObserver(
      (resp) => {
        if (resp[0].isIntersecting && resp[0].intersectionRatio === 1) {
          trackCarDealCardImpression(deal.name);
          CarDealCardObserver.disconnect();
        }
      },
      { root: carDealBoxRef.children[0], threshold: 1 }
    );
    CarDealCardRef.current && CarDealCardObserver.observe(CarDealCardRef.current);
  }, []);

  const handleOnClicked = (dealName: string) => {
    trackCarDealCard(dealName, {
      bookingBegin: deal.pick_up_date,
      bookingEnd: deal.return_date,
      pickupLocation: 'undefined',
      city: dealName
    });
  };

  const getUrlToSearchResult = () => {
    const params = {
      booking_begin: deal.pick_up_date,
      booking_end: deal.return_date,
      location_id: deal.location_id,
      dealers: ''
    };

    let parsed = Object.keys(params).reduce((p, c) => (p += `${c}=${params[c]}&`), '?');
    parsed = parsed.substring(0, parsed.length - 1);

    const searchURL = `${WEB_SEARCH_LINK}/${parsed}`;
    const url = appendParamsCurrentPage(searchURL);

    return url;
  };

  const imageData = getImage(deal.cover);

  return (
    <Card
      className="card--car-deal"
      as="a"
      onClick={() => handleOnClicked(deal.name)}
      href={getUrlToSearchResult()}
      ref={CarDealCardRef}
    >
      {imageData && <GatsbyImage image={imageData} alt={deal.name} className="cover" />}
      <Card.Body>
        <Card.Title className="title">{deal.name}</Card.Title>
        <Card.Subtitle className="mb-1 sub-title">{t('card.subtitle')}</Card.Subtitle>
        <div className="price-box">
          {Object.keys(deal.price).map((key) => {
            const isPriceMoreThanHundred = deal.price[key] >= 1000 ? true : true;
            return (
              <div className="price-box__item" key={key}>
                <div className={`badge badge-${key} badge-sm badge--car-deals`}>
                  {key === 'local' ? 'รถเช่าท้องถิ่น' : 'รถเช่ารายใหญ่'}{' '}
                </div>
                {deal.price[key] === 0 ? (
                  <p className="empty">{t('card.empty')}</p>
                ) : (
                  <>
                    <p className="header">{t('card.header')}</p>
                    <p className="detail">
                      <span className={`detail__price ${isPriceMoreThanHundred ? 'thousand' : ''}`}>
                        {Number(deal.price[key]).toLocaleString()}
                      </span>
                      <span className="detail__unit">{t('card.unit')}</span>
                    </p>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CarDealCard;
