import { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';

import { dataLayer } from '@ui/utils/datalayer';

import './style.scss';

export const AppBanner: FunctionComponent = () => {
  return (
    <div className="app-banner">
      <Container>
        <div className="app-banner__logo">
          <img src="/assets/shares/app-buddy.png" alt="intro-app-buddy" />
        </div>
        <div className="app-banner__content">
          <p className="title">จองผ่านแอป Drivehub ส่วนลดเพียบ!</p>
          <div className="check-list">
            <p>
              <img src="/assets/icons/check-blue.svg" alt="check" />
              <span>รหัสโปรโมชั่นเฉพาะแอป Drivehub</span>
            </p>
            <p>
              <img src="/assets/icons/check-blue.svg" alt="check" />
              <span>วางแผนทริปหน้าได้ทุกที่ทุกเวลา</span>
            </p>
            <p>
              <img src="/assets/icons/check-blue.svg" alt="check" />
              <span>จองสะดวก รวดเร็ว</span>
            </p>
          </div>
          <div className="apps">
            <a
              href="https://apps.apple.com/us/app/drivehub/id1669969277"
              rel="noreferrer"
              target="_blank"
              className="ios-app"
              data-event-category="app_section"
              data-event-action="click"
              data-event-label="ios_app"
              onClick={(e) => dataLayer(e)}
            >
              <img src="/assets/icons/ios-app.svg" className="app-item" alt="IOS App" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.drivehub.user.user"
              rel="noreferrer"
              target="_blank"
              className="android-app"
              data-event-category="app_section"
              data-event-action="click"
              data-event-label="android_app"
              onClick={(e) => dataLayer(e)}
            >
              <img src="/assets/icons/android-app.svg" className="app-item" alt="Android App" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default {};
