import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
// import { Link } from 'gatsby';
import moment from 'moment';
import { navigate } from 'gatsby';
import { Modal } from 'react-bootstrap';
// import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { appendParamsCurrentPage, canUseDOM, readCookie } from '@utils';

import { WEB_BLOG_LINK } from '@config';
import type { IGatsbyImageData } from 'gatsby-plugin-image';

import { trackPromotionClick, trackPromtionClose, trackPromotionSeen } from './tracking';
import './style.scss';

export interface PromotionModalResult {
  kind: string;
  path: string;
  activate: boolean;
  title_th: string;
  title_en: string;
  redirect_to_blog: boolean;
  img_th: IGatsbyImageData;
  img_en: IGatsbyImageData;
  start: string;
  end: string;
}

export const PromotionModal: FunctionComponent<{
  modal: PromotionModalResult;
  prefix: string;
}> = ({ modal: { redirect_to_blog, img_th, img_en, path, title_th, title_en }, prefix }) => {
  const [visible, setVisible] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (canUseDOM()) {
      const consumerToken = readCookie('_consumerToken');
      if (consumerToken) {
        return setVisible(false);
      }
      const lastVisit = window.localStorage.getItem('visited_introduce');
      if (lastVisit === null) return addLocalStorage();
      if (parseInt(lastVisit) > parseInt(moment().unix().toString())) {
        return setVisible(undefined);
      }
      addLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (visible === undefined) return;
    if (visible) {
      return trackPromotionSeen();
    }
    if (!visible) {
      return trackPromtionClose();
    }
  }, [visible]);

  const addLocalStorage = () => {
    if (canUseDOM()) window.localStorage.setItem('visited_introduce', moment().add(2, 'hours').unix().toString());
    setVisible(true);
  };

  const handleHide = useCallback(() => {
    if (canUseDOM()) document.documentElement.style.overflow = 'scroll';
    setVisible(false);
  }, []);

  const getPromotionPageURL = () => {
    const promotionPageURL = `${path}`;
    const url = appendParamsCurrentPage(promotionPageURL);
    return url;
  };

  const handleClickPromotion = useCallback(() => {
    trackPromotionClick();
    if (canUseDOM()) {
      const host = redirect_to_blog ? WEB_BLOG_LINK : `${window.location.origin}/${prefix}`;
      window.location.href = `${host}/${getPromotionPageURL()}`;
      return;
    }
    if (!redirect_to_blog) navigate(`/${prefix}/${path}`);
  }, [redirect_to_blog, prefix]);

  const imageData = useMemo(() => {
    return getImage(prefix === 'en' ? img_en : img_th);
  }, [img_th, img_en]);

  return (
    <Modal
      centered
      show={visible}
      onHide={handleHide}
      className="promotion-modal"
      contentClassName="promotion-modal__content"
    >
      <Modal.Header>
        <div className="close-btn" onClick={handleHide}>
          <i className="icon-close icon-close--modal" /> ปิดหน้าต่างนี้
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }} className="modal-body--promotion">
        {/* <Link onClick={trackPromotionClick} to="/th/promotions/drivehubxktc-2022">
          <StaticImage
            alt="true you ลูกค้าทรูรับส่วนลด 400 บาท"
            layout="fullWidth"
            formats={['auto', 'webp']}
            placeholder="blurred"
            src="../../../../../images/homepage/modal/modal-02-22.jpeg"
            onClick={handleClickPromotion}
          />
        </Link> */}
        {imageData && (
          <GatsbyImage
            image={imageData}
            alt={prefix === 'en' ? title_en : title_th}
            onClick={handleClickPromotion}
            className="promotion-img"
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default { PromotionModal };
