import { PureComponent, MouseEvent } from 'react';
import axios from 'axios';
import { ContextType } from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import { trackBookingRetrieve, BookingRetrieveStatus } from './tracking';
import './style.scss';
type BookingRetrieveState = {
  bookingID: string;
  lastName: string;
  isError: boolean;
};

type BookingRetrieveProps = PageWithTranslationProps;

class SectionBookingRetrieve extends PureComponent<BookingRetrieveProps, BookingRetrieveState> {
  state = {
    bookingID: '',
    lastName: '',
    isError: false
  };

  static contextType = ContextType;
  // GET

  get _isBigbrand() {
    const bookingID = +this.state.bookingID;
    return bookingID < 100000;
  }

  get _source() {
    return this._isBigbrand ? 'inter' : 'local';
  }

  // EVENTS

  _checking = (event: MouseEvent) => {
    event.preventDefault();
    this._callAPI();
  };

  _onBookingIDChange = (event: any) => {
    this.setState({
      bookingID: event.target.value,
      isError: false
    });
  };

  _onLastNameChange = (event: any) => {
    this.setState({
      lastName: event.target.value,
      isError: false
    });
  };

  // METHODS

  _setupAxios() {
    const { bigbrandAPI, backendAPI } = this.context;

    if (this._isBigbrand) {
      axios.defaults.baseURL = bigbrandAPI;
    } else {
      axios.defaults.baseURL = backendAPI;
    }
  }

  _callAPI = () => {
    const { bookingID, lastName } = this.state;
    const { searchHost } = this.context;

    this._setupAxios();

    axios
      .get(`/bookings/${bookingID}?last_name=${lastName}`)
      .then(() => {
        const path = `${searchHost}/booking/${bookingID}?source=${this._source}&last_name=${lastName}`;
        trackBookingRetrieve(BookingRetrieveStatus.Success);
        window.location.href = path;
      })
      .catch((error) => {
        console.log(`Search booking retrieve error: ${error}`);
        trackBookingRetrieve(BookingRetrieveStatus.Fail);
        this.setState({ isError: true });
      });
  };

  // RENDER

  render() {
    const { t } = this.props;

    return (
      <section id="manage-booking" className="manage-booking">
        <div className="container">
          <h3>{t('title')}</h3>
          <form>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('placeholder-booking-id')}
                  onChange={this._onBookingIDChange}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-5">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t('placeholder-last-name')}
                  onChange={this._onLastNameChange}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-2">
                <button type="submit" className="btn btn-primary mb-2 btn-block" onClick={this._checking}>
                  {t('btn-submit')}
                </button>
              </div>
            </div>
            {this.state.isError && <div className="error-message">{t('error-message')}</div>}
          </form>
        </div>
      </section>
    );
  }
}

export default withTrans('booking-retrieve')(SectionBookingRetrieve);
