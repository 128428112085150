import { PureComponent, FunctionComponent } from 'react';
import axios from 'axios';

import DhSlick from '@shares/dh-slick';
import { DhPaging } from '@shares/dh-slick/custom-slick';
import { Settings } from 'react-slick';

import { withTrans } from '@shares/locales/hoc';
import { DRIVEHUB_API } from '@config';
import { CarDealsQueryResult, CarDealsProps, CarDealsState, CarDeal } from './interface';
import { StaticQuery, graphql } from 'gatsby';
import { canUseDOM } from '@utils';

import Loading from '@shares/loading';
import Card from './card';
import Container from 'react-bootstrap/Container';
import { trackingCarDealArrow, trackingCarDealPaging } from './tracking';
import './style.scss';

const DEFAULT_SLIDES_TO_SHOW = 5;
class SectionCarDeals extends PureComponent<CarDealsProps, CarDealsState> {
  constructor(props: CarDealsProps) {
    super(props);
    this.state = {
      car_deals: [],
      slidesToShow: DEFAULT_SLIDES_TO_SHOW,
      is_loading: true
    };
  }

  componentDidMount() {
    this.fetchCities();
    if (canUseDOM()) this.getSlidesToShow(window.innerWidth);
    window.addEventListener('resize', (e) => this.handleResponsive(e), false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', (e) => this.handleResponsive(e));
  }

  getSlidesToShow = (width: number) => {
    if (width <= 992) return;
    switch (true) {
      case width <= 1201:
        this.setState({ slidesToShow: 4 });
        return 4;
      default:
        this.setState({ slidesToShow: 5 });
        return 5;
    }
  };
  handleResponsive = (e: any) => {
    const width = e.target.innerWidth;
    return this.getSlidesToShow(width);
  };

  // Call API
  fetchCities = async () => {
    const { carDealsDefault } = this.props;

    axios({
      method: 'post',
      url: `${DRIVEHUB_API}/v1/car_deals`,
      data: {
        carDealsDefault: carDealsDefault
      }
    })
      .then(({ data }: { data: CarDeal[] }) => {
        this.setState({ car_deals: data, is_loading: false });
      })
      .catch((err) => console.log(err));
  };

  // Setting
  slickSettings = (): Settings => {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: this.state.slidesToShow,
      slidesToScroll: 1,
      swipeToSlide: true,
      customPaging: (i) => <DhPaging i={i} handleTracking={(index) => trackingCarDealPaging(index + 1)} />,
      responsive: [
        {
          breakpoint: 1201,
          settings: {
            slidesToShow: this.state.slidesToShow
          }
        },
        {
          breakpoint: 992,
          settings: {
            className: 'slider variable-width slider--dh-mobile',
            variableWidth: true,
            arrows: false,
            speed: 800,
            slidesToShow: 1,
            dots: false,
            useTransform: false,
            useCSS: false
          }
        }
      ]
    };
  };

  renderCarCards() {
    const { car_deals, is_loading } = this.state;
    const { t } = this.props;

    return (
      <DhSlick
        settings={this.slickSettings()}
        isLoading={is_loading}
        sectionName="car_deals"
        onClickArrow={(e, direction) => {
          trackingCarDealArrow(e, direction);
        }}
      >
        {car_deals.map((deal, index) => {
          return <Card key={deal.city_id} deal={deal} cardIndex={index + 1} t={t} />;
        })}
      </DhSlick>
    );
  }

  render() {
    const { t } = this.props;
    const { car_deals, is_loading } = this.state;

    return (
      <div className="car-deals" id="car-deals-box">
        <Container>
          <h2 className="title">{t('header')}</h2>
          <div className="card-container">
            {is_loading && <Loading />}
            {!is_loading && car_deals.length !== 0 && this.renderCarCards()}
          </div>
          <div className="remark">{t('remark')}</div>
        </Container>
      </div>
    );
  }
}

const CarDeals: FunctionComponent<CarDealsProps> = (props: CarDealsProps) => (
  <StaticQuery
    query={graphql`
      query CarDeals {
        allMarkdownRemark(filter: { fields: { collection: { eq: "car-deals" } } }) {
          edges {
            node {
              frontmatter {
                name_th
                name_en
                cover {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                max_price
                city_id
                position
              }
            }
          }
        }
      }
    `}
    render={(data: CarDealsQueryResult) => {
      const rawCarDealsDefault = data.allMarkdownRemark.edges;
      const carDealsDefault = rawCarDealsDefault.map((carDeal) => {
        return carDeal.node.frontmatter;
      });

      carDealsDefault.map((carDeal) => {
        carDeal.city_id = Number(carDeal.city_id);
        carDeal.max_price = Number(carDeal.max_price);

        if (carDeal.position != null) {
          carDeal.position = Number(carDeal.position);
        }
        return carDeal;
      });

      return <SectionCarDeals {...props} carDealsDefault={carDealsDefault} />;
    }}
  />
);

export default withTrans('car-deals')(CarDeals);
