import { sendToDataLayer } from '@utils';

const MODAL_TRACK_CATEGORY = 'popup_section';
const BANNER_NAME = 'drivehub_เว็บไซต์รวมรถเช่าราคาถูก_ที่มีคนบอกต่อมากที่สุด';

export const trackModalSeen = () => {
  sendToDataLayer(undefined, {
    event_category: MODAL_TRACK_CATEGORY,
    event_action: 'seen',
    event_label: `${BANNER_NAME}`
  });
};

export const trackModalClose = () => {
  sendToDataLayer(undefined, {
    event_category: MODAL_TRACK_CATEGORY,
    event_action: 'close',
    event_label: `${BANNER_NAME}`
  });
};
