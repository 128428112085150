import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Link } from 'gatsby';
import { Modal } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';

import { canUseDOM } from '@utils';

import { trackModalSeen, trackModalClose } from './tracking';
import './style.scss';

export const IntroduceModal: FunctionComponent<EmptyProps> = () => {
  const [visible, setVisible] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (canUseDOM()) {
      const lastVisit = window.localStorage.getItem('visited_introduce');
      if (lastVisit === null) return addLocalStorage();
      if (parseInt(lastVisit) > parseInt(moment().unix().toString())) {
        return setVisible(undefined);
      }
      addLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (visible === undefined) return;
    if (visible) {
      return trackModalSeen();
    }
    if (!visible) {
      return trackModalClose();
    }
  }, [visible]);

  const addLocalStorage = () => {
    if (canUseDOM()) window.localStorage.setItem('visited_introduce', moment().add(2, 'hours').unix().toString());
    setVisible(true);
  };

  const handleHide = useCallback(() => {
    if (canUseDOM()) document.documentElement.style.overflow = 'scroll';
    setVisible(false);
  }, []);

  return (
    <Modal
      centered
      show={visible}
      onHide={handleHide}
      className="introduce-modal"
      contentClassName="introduce-modal__content"
    >
      <Modal.Header className="modal-header">
        <div className="close-btn" onClick={handleHide}>
          <i className="icon-close icon-close--modal" /> ปิดหน้าต่างนี้
        </div>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Link to="/" partiallyActive>
          <StaticImage
            alt="บริษัทรถเช่ามากกว่า 500 บริษัท"
            layout="fullWidth"
            formats={['auto', 'webp']}
            placeholder="blurred"
            onClick={handleHide}
            src="../../../../../images/homepage/modal/default-modal-10_22.jpg"
          />
        </Link>
      </Modal.Body>
    </Modal>
  );
};

export default { IntroduceModal };
