import { sendToDataLayer } from '@utils';
const MODAL_TRACK_CATEGORY = 'popup_section';

export const trackPromotionSeen = () => {
  sendToDataLayer(undefined, {
    event_category: MODAL_TRACK_CATEGORY,
    event_action: 'seen',
    event_label: `เช่ารถรายเดือน_ราคาพิเศษกับ_drivehub`
  });
};

export const trackPromtionClose = () => {
  sendToDataLayer(undefined, {
    event_category: MODAL_TRACK_CATEGORY,
    event_action: 'close',
    event_label: `เช่ารถรายเดือน_ราคาพิเศษกับ_drivehub`
  });
};
export const trackPromotionClick = () => {
  sendToDataLayer(undefined, {
    event_category: MODAL_TRACK_CATEGORY,
    event_action: 'click',
    event_label: `เช่ารถรายเดือน_ราคาพิเศษกับ_drivehub`
  });
};
