import { sendToDataLayer } from '@utils';
import moment from 'moment';

const CAR_DEAL_TRACK_CATEGORY = 'special_price_section';
interface DealDetail {
  bookingBegin: string;
  bookingEnd: string;
  city: string;
  pickupLocation: string;
}
export const trackCarDealCardImpression = (dealName: string) => {
  sendToDataLayer(undefined, {
    event_category: CAR_DEAL_TRACK_CATEGORY,
    event_action: 'impression',
    // FYI: dealName = sub_menu_name
    event_label: `specialprice_card_${dealName}`
  });
};

export const trackCarDealCard = (dealName: string, dealDetail: DealDetail) => {
  const { bookingBegin, bookingEnd, pickupLocation, city } = dealDetail;
  const customEvent = {
    search_return_date: `${moment(bookingEnd).locale('en').format('YYYY-MMM-DD')}`,
    search_return_time: `${moment(bookingEnd).locale('en').format('HH:mm:ss')}`,
    search_destination: `${city}`,
    search_pickup_date: `${moment(bookingBegin).format('YYYY-MMM-DD')}`,
    search_pickup_time: `${moment(bookingBegin).format('HH:mm:ss')}`,
    search_pickup_location: `${pickupLocation}`,
    search_dealer_type: 'undefined',
    search_dealer_name: 'undefined',
    search_car_type: 'undefined',
    search_promotion_name: 'undefined',
    search_rental_type: 'undefined',
    sort_by: 'undefined',
    search_time_stamp: moment().locale('en').format('YYYY-MMM-DD HH:mm:ss')
  };

  sendToDataLayer(undefined, {
    event_category: CAR_DEAL_TRACK_CATEGORY,
    event_action: 'search_bookings_success',
    // FYI: dealName = sub_menu_name
    event_label: `specialprice_card_${dealName}`,
    ...customEvent
  });
};

export enum ArrowDirection {
  Next = 'arrow_right',
  Prev = 'arrow_left'
}
const getArrowTrackDirection = (d: string) => {
  switch (d) {
    case 'next':
      return ArrowDirection.Next;
    case 'prev':
      return ArrowDirection.Prev;
    default:
      return '';
  }
};
export const trackingCarDealArrow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, direction: string) => {
  sendToDataLayer(e, {
    event_category: CAR_DEAL_TRACK_CATEGORY,
    event_label: `${getArrowTrackDirection(direction)}`
  });
};

export const trackingCarDealPaging = (index: number) => {
  sendToDataLayer(undefined, {
    event_category: CAR_DEAL_TRACK_CATEGORY,
    event_label: `pagination_${index}`
  });
};
