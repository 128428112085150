import { FunctionComponent, useState } from 'react';
import LazyLoad from 'react-lazyload';
import ApplicationModal from '../../components/app-modal';
import DHPicture from '@shares/picture';
import Container from 'react-bootstrap/Container';
import './style.scss';

export enum ApplicationType {
  IOS = 'ios',
  Android = 'android'
}

const SectionApplication: FunctionComponent = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [type, setType] = useState<ApplicationType>(ApplicationType.Android);
  const handleOnClickApp = (type: ApplicationType) => {
    setType(type);
    setVisible(!visible);
    // sendToGAByFixedText('mobile_app_dummy', 'download', type);
  };
  return (
    <div className="application">
      <ApplicationModal visible={visible} setVisible={setVisible} type={type} />
      <LazyLoad height={303}>
        <Container>
          <div className="text-center">
            <h2>ดาวน์โหลดแอปพลิเคชั่น</h2>
            <p>ค้นหา จอง และจัดการทริปของคุณผ่านแอป</p>
            <div className="application-content d-flex">
              <DHPicture
                className="pb-3 pb-lg-0 pr-0 pr-lg-2"
                webpScr="/assets/pages/homepage/application/play-store-btn.webp"
                normalSrc="/assets/pages/homepage/application/play-store-btn.png"
                alt="play-store"
                onClick={() => handleOnClickApp(ApplicationType.Android)}
              />
              <DHPicture
                className="pl-0 pl-lg-2"
                webpScr="/assets/pages/homepage/application/app-store-btn.webp"
                normalSrc="/assets/pages/homepage/application/app-store-btn.png"
                alt="app-store"
                onClick={() => handleOnClickApp(ApplicationType.IOS)}
              />
            </div>
          </div>
        </Container>
      </LazyLoad>
    </div>
  );
};

export default SectionApplication;
