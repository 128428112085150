import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { FormikErrors } from 'formik';
import { object, string, InferType } from 'yup';
import { FunctionComponent } from 'react';

export const applicationSchema = () =>
  object()
    .shape({
      email: string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
        .required('กรุณากรอกอีเมลของคุณ')
        .defined(),
      platform: string().defined()
    })
    .defined();

export type ApplicationFormValues = InferType<ReturnType<typeof applicationSchema>>;

type ApplicationFormFieldProps = {
  values: ApplicationFormValues;
  errors: FormikErrors<ApplicationFormValues>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  isError: boolean;
  isSubmitting: boolean;
};
const ApplicationFormField: FunctionComponent<ApplicationFormFieldProps> = (props) => {
  const { values, errors, handleChange, handleBlur, isError, isSubmitting } = props;
  return (
    <>
      <Col xs={8} className="pl-0 pr-1">
        <Form.Group>
          <Form.Control
            id="application-register"
            type="text"
            placeholder="อีเมล"
            name="email"
            value={values.email}
            onChange={handleChange}
            isInvalid={!!errors.email}
            onBlur={handleBlur}
            autoComplete="off"
            onKeyPress={(e: any) => {
              const code = e.which ? e.which : e.keyCode;
              if (code == 32) {
                e.preventDefault();
              }
            }}
          />
          <Form.Control.Feedback type="invalid" className="text-left">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={3} className="pr-0 pl-1">
        <Button
          id="application-register-submit"
          type="submit"
          block
          variant={isError ? 'danger' : 'primary'}
          disabled={isSubmitting || isError ? true : false || Object.keys(errors).length > 0}
          className={isSubmitting ? 'submiting' : ''}
        >
          {isSubmitting ? <img src="/assets/shares/loading.svg" style={{ height: '20px' }} /> : isError ? 'ส่ง' : 'ส่ง'}
        </Button>
      </Col>
    </>
  );
};

export default ApplicationFormField;
