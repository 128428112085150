import { FunctionComponent } from 'react';
import './style.scss';
interface DHPictureProps {
  webpScr: string;
  normalSrc: string;
  alt: string;
  imgClassName?: string;
  className?: string;
  onClick?: () => void;
}
const DHPicture: FunctionComponent<DHPictureProps> = ({
  webpScr,
  normalSrc,
  alt,
  imgClassName,
  className,
  onClick
}) => {
  return (
    <picture className={`dh-picture ${className ? className : ''}`}>
      <source
        data-srcset={webpScr}
        type="image/webp"
        src="/assets/shares/loading.svg"
        className={`lazyload ${imgClassName ? imgClassName : ''}`}
      />
      <img
        data-src={normalSrc}
        alt={alt}
        src="/assets/shares/loading.svg"
        className={`lazyload ${imgClassName ? imgClassName : ''}`}
        onClick={() => {
          if (onClick) return onClick();
          return;
        }}
      />
    </picture>
  );
};

export default DHPicture;
